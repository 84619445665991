<script lang="ts" setup>
import type { ItemManagementEmits, ItemManagementProps } from './types';

import { InputComboLayout, SettingLayout } from '@gem/element-setting-ui';
import { GReorder } from '@gem/uikit';
import AddItemButton from './components/item-action-components/AddItemButton.vue';
import Item from './components/Item.vue';
import { DEFAULT_PROPS } from './constants';
import { useItemManagement } from './hooks/useItemManagement';

const emit = defineEmits<ItemManagementEmits>();
const props = withDefaults(defineProps<ItemManagementProps>(), DEFAULT_PROPS);

const { items, inputComboLayoutContent, handleAppendItem, handleReorderItem } = useItemManagement(props, emit);
</script>

<template>
  <InputComboLayout
    :id="id"
    placeholder="Add..."
    :is-parent="true"
    :show-label="false"
    :is-full-width="true"
    :is-hide-clear="true"
    :label="{ en: 'Item management' }"
    :content-display="inputComboLayoutContent"
    :combo-icon="'polaris-list-bulleted'"
    :level="level || 0"
    :combo-i-d="comboID || ''"
    :combo-parent-i-d="comboParentID || ''"
    :combo-root-parent-i-d="comboRootParentID || ''">
    <SettingLayout layout="vertical">
      <template #control>
        <div class="relative mr-[-14px]">
          <perfect-scrollbar class="items-list-wrapper max-h-[352px] [&:has(.dragging)]:cursor-grabbing">
            <GReorder item-gap="4px" :items="items" @re-order="handleReorderItem">
              <template #default="{ item, index }">
                <Item
                  :id="index.toString()"
                  :index="index.toString()"
                  :item="item"
                  :tag="tag"
                  :is-expand="expandItem" />
              </template>
            </GReorder>
          </perfect-scrollbar>
          <AddItemButton v-if="hasAddMore" @append-item="handleAppendItem" />
        </div>
      </template>
    </SettingLayout>
  </InputComboLayout>
</template>
